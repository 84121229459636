import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import 'view-ui-plus/dist/styles/viewuiplus.css'

router.beforeEach((to,form,next)=>{
    if(to.meta.title){
        document.title=to.meta.title;
    }
    next();
})

const app = createApp(App).use(router)
app.use(ViewUIPlus)
.use(router)
.mount('#app')
